import axiosIns from '@/libs/axios'

export default class TicketsApis {
  getUnreadTickets = async () => await axiosIns.get('/auth/ticketmodule/opens');

  getReadTickets = async data => await axiosIns.get(
    `/auth/ticketmodule/closes?page=${data.currentReadPage}&per_page=${data.perPage}${data.search ? `&search=${data.search}` : ''}${data.sort ? `&sort=${data.sort}` : ''}${data.sortType ? `&sort_type=${data.sortType}` : ''}`,
  )

  getTicketCategory = async () => await axiosIns.get('/auth/ticketmodule/categories');

  createNewTicket = async data => {
    const formData = new FormData()
    Object.keys(data).forEach(element => {
      if (data[element]) {
        formData.append(element, data[element])
      }
    })
    return await axiosIns.post('/auth/ticketmodule/create', formData)
  };

  sendTicketAnswer = async ({ id, answerTicket }) => {
    const formData = new FormData()
    Object.keys(answerTicket).forEach(element => {
      if (answerTicket[element]) {
        formData.append(element, answerTicket[element])
      }
    })
    return await axiosIns.post(`/auth/ticketmodule/answer/${id}`, formData)
  };

  getTicketsById = async id => await axiosIns.get(`/auth/ticketmodule/get/${id}`);

  closeTicketById = async id => await axiosIns.post(`/auth/ticketmodule/close/${id}`);
}
