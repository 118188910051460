var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.show,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-row',[_c('b-col',[_c('b-card',[_c('b-row',{staticClass:"mb-2 pb-1",staticStyle:{"border-bottom":"1px solid #ddd"},attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"text-center",staticStyle:{"cursor":"pointer"},attrs:{"cols":"auto"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t("back"))+" ")]),_c('b-col',{attrs:{"cols":"12"}},[(_vm.$i18n.isRTL)?_c('feather-icon',{attrs:{"icon":"ArrowRightIcon","size":"14"}}):_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon","size":"14"}})],1)],1),_c('b-col',{staticClass:"leftBorder"},[_c('b-row',{attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{attrs:{"cols":"auto"}},[_c('h3',[_vm._v(_vm._s(_vm.ticketData.title))]),_c('span',[_vm._v(_vm._s(_vm.ticketData.category_title))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"auto"}},[_c('h6',[_vm._v(_vm._s(this.$t("last_update")))]),_c('span',[_vm._v(" "+_vm._s(new Date(_vm.ticketData.updated_at).toLocaleString( _vm.$i18n.locale ))+" ")])])],1)],1)],1),_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"cols":"12","xl":"11"}},[_c('TicketItem',{attrs:{"ticket-data":{
                answer_by: this.$t('user'),
                created_at: _vm.ticketData.created_at,
                context: _vm.ticketData.context,
                file: _vm.ticketData.file,
                // icon_read: ticketData.icon_read
              }}}),((_vm.ticketData.answers || []).length !== 0)?_c('div',{staticClass:"mb-3 py-0"},_vm._l((_vm.ticketData.answers),function(item){return _c('TicketItem',{key:item.id,attrs:{"ticket-data":item}})}),1):_vm._e(),(_vm.ticketData.status_value === 'open' && _vm.ticketData.read_by === 'operator')?_c('b-row',{staticClass:"shadow px-2 py-2 rounded-lg",attrs:{"align-h":"between","align-v":"center"}},[_c('b-col',{staticClass:"py-2 py-sm-0",attrs:{"cols":"auto"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('close_ticket_description')))]),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('close_ticket_automatically')))])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"flex-end",attrs:{"variant":"danger","inline":""},on:{"click":_vm.closeTicket}},[(!_vm.closeLoading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("close_ticket")))]):_c('b-spinner',{attrs:{"small":"","variant":"light","type":"grow"}})],1)],1)],1):_vm._e(),(_vm.ticketData.status_id === 2)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{staticClass:"p-2",attrs:{"show":true,"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('closed_ticket_message'))+" ")])],1)],1):_vm._e(),(_vm.ticketData.status_id !== 2)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-5",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitAnswer.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('answer'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"answer","label-for":"answer"}},[_c('b-form-textarea',{attrs:{"id":"answer","placeholder":_vm.$t('answer'),"rows":"8"},model:{value:(_vm.form.answer),callback:function ($$v) {_vm.$set(_vm.form, "answer", $$v)},expression:"form.answer"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-row',{attrs:{"align-v":"center","align-h":"between"}},[_c('b-col',{attrs:{"cols":"12","sm":"auto"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('image'),"rules":'required|size:'+_vm.MAX_IMAGE_UPLOAD_SIZE},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var validate = ref.validate;
return [_c('div',{staticClass:"d-flex flex-row-reverse"},[(_vm.form.file)?_c('feather-icon',{staticClass:"text-danger stroke-current",attrs:{"icon":"XIcon","size":"28","variant":"danger"},on:{"click":function($event){_vm.form.file = null}}}):_vm._e(),_c('b-form-file',{attrs:{"plain":"","accept":"image/png, image/jpg, image/jpeg"},on:{"change":validate},model:{value:(_vm.form.file),callback:function ($$v) {_vm.$set(_vm.form, "file", $$v)},expression:"form.file"}})],1),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mt-1 mt-sm-0",attrs:{"cols":"12","sm":"auto"}},[_c('b-button',{staticClass:"flex-end",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[(!_vm.isLoading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("createTicketPage.form.btn")))]):_c('b-spinner',{attrs:{"small":"","variant":"light","type":"grow"}})],1)],1)],1)],1)]}}],null,false,2217027546)}):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }